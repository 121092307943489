import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';
import { LARGE_PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/LargeProductImage/Base';
import { BOOTH_BASE_FRAGMENT } from '@/graphql/_Fragments/Booth/Base';

export const LARGE_PRODUCT_BOAT_CARD_WITH_EXTRA_PROPERTIES_FRAGMENT = `
  fragment largeProductBoatCardWithExtraPropertiesFragment on LargeProduct {
    id
    uid
    schemaCode
    name
    price
    featured
    images(orderBy: [id_asc]) {
      ...largeProductImageBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    booths: _boothsOfEdition {
      ...boothBaseFragment
    }
    extraProperties(filter: {
      key_in: "%extraProperties%"
    }) {
      key
      value
      config {
        name
        key
        targetType
        valueType
        displaySequence
      }
    }
    _isRecommendedForMe
    _isExhibitorFeatured
    _isBookmarked(myUid: "%authUser%")
  }
  ${LARGE_PRODUCT_IMAGE_BASE_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
  ${BOOTH_BASE_FRAGMENT}
`;
